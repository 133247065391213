import React, { useEffect, useState } from 'react';
import {AiOutlineArrowLeft} from 'react-icons/ai';
import {BiSolidCoinStack} from 'react-icons/bi';
import {AiFillCalendar} from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

const Stacking = () => {
    const [data,setData] = useState("");
const[teir1,setTeir1] = useState(1)
const[stk,setStk] = useState(0)
const[teir2,setTeir2] = useState(0)
const[active_ref,setActiveRef] = useState(0)
const [total_ice_count,setTotalIceCount] = useState();
const [isCheckboxChecked, setCheckboxChecked] = useState(false);
const [success, setSuccess] = useState(false);

const handleChangeTeir1 =(e)=>{
 setTeir1(e.target.value)
 setTotalIceCount((3+2*e.target.value)+(3*teir2)+(1*active_ref))

}

const handleChangeTeir2 =(e)=>{
 setTeir2(e.target.value)
 setTotalIceCount((3+3*e.target.value)+(2*teir1)+(1*active_ref))
}

const handleChangeActiveRef =(e)=>{
 setActiveRef(e.target.value)
 setTotalIceCount((3+1*e.target.value)+(3*teir2)+(2*teir2))

}




useEffect(()=>{
 setTotalIceCount((3+2*teir1)+(3*teir2)+(1*active_ref))
},[])

  const coin = async() => {
//     var formdata = new FormData();
// formdata.append("token", localStorage.getItem('token'));

// var requestOptions = {
//   method: 'POST',
//   body: formdata,
//   redirect: 'follow'
// };

// fetch("http://swamivivekanandshikshasevasansthan.in/api/rank", requestOptions)
//   .then(response => response.json())
//   .then(result => {console.log(result)
//   let mm =JSON.parse(result)
//   if (result.status == "001") {
//     setData(mm.data)
//   }
// })
//   .catch(error => console.log('error', error));


var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/rank", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status=="001"){
         setData(result.data) 
    }
    else{
      
    }
  })
  .catch(error => console.log('error', error));

  }


const stack =()=>{
    var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));
formdata.append("staking", teir1);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/staking", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status=="001"){
        console.log(result,"hello everyone get it")
    }
    else{
        console.log(result,"hhkk")

    }
  })
  .catch(error => console.log('error', error));
}


  useEffect(()=>{
    coin(); 
  },[])
  return (
    <div>

    <div className='container '>
        <div className='row'>
            <div className='col-lg-12 col-sm-12'>


            <div className='row pt-2' style={{backgroundColor:'#2b2a65',   borderRadius:'0px 0px 20px 20px'}}>
              <div className='col-2'>
              <NavLink to='/' stylez={{color:'#fff'}}> <AiOutlineArrowLeft style={{fontSize:'22px'}}/></NavLink>

              </div>
              <div className='col-10'>
              <h5 className=' ps-5 text-white'>Pre-Staking</h5>

              </div>

              <div className='row py-5 mb-5 pb-5'>
                <div className='col-11 m-auto'>
                <p className='text-center text-white'  style={{fontSize:'14px'}}> Pre-Staking icube your Rewards for up to 5 Years and increase your Earning rate by up to 250%</p>

                </div>
              </div>
            </div>



             

        <div style={{marginTop:'-60px'}}> 
            <div className='card mb-5 my-3 pb-4' style={{backgroundColor:'#2d7c6c', color:'#fff', borderRadius:'10px', }}>
          <div className='card-body'>
          <div >
          <h6>EARNING CALCULATOR</h6>
          <div>
            <div  style={{
              display:"flex",
              flexDirection:"column",
              alignItems:"cneter",
              justifyContent:"center",
            }}>
<h6>You will earn:</h6>
{/* <h2>{total_ice_count}* icube/h</h2> */}
<div className='mt-3' style={{display:'flex', justifyContent:'space-between'}}>
<button style={{border:'1px solid #fff ', borderRadius:'8px',backgroundColor:'transparent',color:'#fff', padding:'5px 5px', fontSize:'14px'}}>1 Years = 30</button>
<button style={{border:'1px solid #fff ', borderRadius:'8px',backgroundColor:'transparent',color:'#fff',padding:'5px 5px',fontSize:'14px'}}>
2 Years = 35</button>
<button style={{border:'1px solid #fff ', borderRadius:'8px',backgroundColor:'transparent',color:'#fff',padding:'5px 5px',fontSize:'14px'}}> 3 Years = 40</button> 
            </div>
            </div>
            <div className='mt-4'>
              <div style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between"
              }}>
                <h6 style={{display:'flex',alignItems:'center'}}>  <AiFillCalendar className='me-2'/> Years</h6>
                <h6>{teir1}</h6>
              </div>
              <div >
        
     <input value={teir1}  onChange={handleChangeTeir1} min={1} max={3} type="range"  style={{
      accentColor: "0d6efd",
      width: "100%"
     }}/></div>



     
            </div>
           
          </div>
        </div>

          </div>
        </div>
        </div>





{/* <div className='mt-4' style={{display:'flex', alignItems:'center'}}>

  <div class="form-check">
  <input class="form-check-input " type="checkbox" value="" id="flexCheckDefault"/>
  <p className='px-2' style={{fontSize:'13px', textAlign:'center'}}> i agree the iqube Staking Terms Your iqube Holding will be locked for the selected period</p>
</div>

</div>

<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
    <button onClick={()=>{stack()}} style={{backgroundColor:'#2b2a65', color:'#fff', border:'none', padding:'9px 20px', borderRadius:'8px', width:'90%', fontSize:'18px'}}>  <BiSolidCoinStack className='me-3'/>Submit</button>
 </div> */}


 <div className='mt-4' style={{ display: 'flex', alignItems: 'center' }}>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value={isCheckboxChecked}
          id="flexCheckDefault"
          onChange={() => setCheckboxChecked(!isCheckboxChecked)}
        />
        <p className='px-2' style={{ fontSize: '13px', textAlign: 'center' }}>
          I agree the iqube Staking Terms. Your iqube Holding will be locked for the selected period.
        </p>
      </div>
    </div>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <button  
        onClick={stack}
        style={{
          backgroundColor: '#2b2a65',
          color: '#fff',
          border: 'none',
          padding: '9px 20px',
          borderRadius: '8px',
          width: '90%',
          fontSize: '18px', 
        }}
        disabled={!isCheckboxChecked}  // Disable the button if the checkbox is not checked
      >



        <BiSolidCoinStack className='me-3' />
        Submit
      </button>
    </div>


            </div>
        </div>
    </div>
      
    </div>
  )
}

export default Stacking
