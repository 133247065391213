import { useEffect, useState } from "react";
import "./Order.css";
import Order1 from "./Order1";
import Order2 from "./Order2";
import Order3 from "./Order3";
import { AiOutlineSearch } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { FaGripLinesVertical } from "react-icons/fa";
import { PiWalletBold } from "react-icons/pi";

export default function User() {
  const [State, setState] = useState("");
  const [data, setData] = useState("");
  const [reffer, setreffer] = useState();





  const demo = () =>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("http://swamivivekanandshikshasevasansthan.in/api/userlevel2", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status=="001"){
          setreffer(result.count)
          console.log(result,"sorry error is here")
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(()=>{
    demo();
  },[])

  const getLevels = () => {
    var formdata = new FormData();
    formdata.append(
      "token",
      localStorage.getItem("token")
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://swamivivekanandshikshasevasansthan.in/api/levels",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "levels");
      })
      .catch((error) => console.log("error", error));
  };

  

  const getLevel2 = () => {
    var formdata = new FormData();
    formdata.append(
      "token",
      localStorage.getItem("token")
      );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://swamivivekanandshikshasevasansthan.in/api/level2",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "2");
      })
      .catch((error) => console.log("error", error));
  };

  const getLevel3 = () => {
    var formdata = new FormData();
    formdata.append(
      "token",
      "$2y$10$D9mPu7yBxGTYo1JlXgmRyOUacd.NJ3lo2qGauluXPFyTX6e9OYR6a"
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://swamivivekanandshikshasevasansthan.in/api/level3",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "3");
      })
      .catch((error) => console.log("error", error));
  };

  const getLevel4 = () => {
    var formdata = new FormData();
    formdata.append(
      "token",
      "$2y$10$D9mPu7yBxGTYo1JlXgmRyOUacd.NJ3lo2qGauluXPFyTX6e9OYR6a"
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://swamivivekanandshikshasevasansthan.in/api/level4",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "4");
      })
      .catch((error) => console.log("error", error));
  };

  const getLevel5 = () => {
    var formdata = new FormData();
    formdata.append(
      "token",
      "$2y$10$D9mPu7yBxGTYo1JlXgmRyOUacd.NJ3lo2qGauluXPFyTX6e9OYR6a"
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://swamivivekanandshikshasevasansthan.in/api/level4",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const getLevel6 = () => {};

  const Ref =()=>{
    var formdata = new FormData();
formdata.append(
  "token",
  localStorage.getItem("token")
  );
var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/reffer", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status=="001"){
      setData(result.data)
    }else{

    }
  })
  .catch(error => console.log('error', error));
  }

  

  // useEffect(() => {
  //   getLevel2();
  //   getLevel3();
  //   getLevel4();
  //   getLevel5();
  //   getLevels();
  //   setState("Order1");
  //   Ref();
  // }, []);


  
  return (
    <>
      <section style={{ backgroundColor: "rgb(45, 124, 108)", height: "60vh" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
              <div
                className="d-flex mt-1"
                style={{ backgroundColor: "white", borderRadius: "25px" }}
              >
                <AiOutlineSearch className="OrderIcon1" />
                <input
                  className="OrderInput"
                  type="text"
                  name="user"
                  placeholder="Search for users"
                />
              </div>
            </div>
            <div className="row mt-3 m-auto">
              <div className="col-5">
                <div className="row m-auto">
                  <div className="col-4">
                    <FaUsers
                      style={{ color: "white", fontSize: "30px" }}
                      className="OrderIcon2"
                    />
                  </div>
                  <div className="col-8">
                    <p className="OrderPp1">Referalls</p>
                    <p className="OrderPp2"> {reffer} </p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                {/* <hr style={{transform:"rotate(90deg)",color:'white',height:'5px',backgroundColor:'white',fontWeight: '900'}}></hr> */}
                <FaGripLinesVertical
                  style={{ color: "white", fontSize: "28px" }}
                />
              </div>
              <div className="col-5">
                <div className="row m-auto">
                  <div className="col-4">
                    <PiWalletBold
                      style={{ color: "white", fontSize: "30px" }}
                      className="OrderIcon3 mt-2"
                    />
                  </div>
                  <div className="col-8">
                    <p className="OrderPp3">Earnings</p>
                    <p className="OrderPp4"> 0 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card mt-3"
          style={{
            width: "100%",
            height: "60vh",
            borderRadius: "25px 25px 0px 0px",
            borderBottom: "none",
          }}
        >
          <div class="card-body">
            <div
              className="OrderCard-fluid card"
              style={{
                width: "100%",
                // height: "9vh",
                border: "1px solid #677079",
                borderRadius: "20px",
              }}
            >
              <div className="row my-2  " style={{ zIndex: "999" }}>
                <div className="col-4 ">
                  <div className="text-center">
                    <button
                      onClick={() => {
                        setState("Order1");
                      }}
                      className="OrderBtnAll "
                      style={{
                        border: "none",
                        backgroundColor:
                          State === "Order1" ? "rgb(45, 124, 108)" : "white",
                        color: State === "Order1" ? "#FFFFFF" : "#000000",
                      }}
                    >
                      Lvl-1
                    </button>
                  </div>
                </div>
                <div className="col-4">
                  <div className="text-center">
                    <button
                      onClick={() => {
                        setState("Order2");
                      }}
                      className="OrderBtnPending  "
                      style={{
                        border: "none",
                        backgroundColor:
                          State === "Order2" ? "rgb(45, 124, 108)" : "white",
                        color: State === "Order2" ? "#FFFFFF" : "#000000",
                      }}
                    >
                      Lvl-2
                    </button>
                  </div>
                </div>
                <div className="col-4">
                  <div className="text-center">
                    <button
                      onClick={() => {
                        setState("Order3");
                      }}
                      className="OrderBtnComplete"
                      style={{
                        border: "none",
                        backgroundColor:
                          State === "Order3" ? "rgb(45, 124, 108)" : "white",
                        color: State === "Order3" ? "#FFFFFF" : "#000000",
                      }}
                    >
                      Lvl-3
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {State === "Order1" || State === "" ? (
                <>
                  <Order1 />
                </>
              ) : (
                <></>
              )}
              {State === "Order2" ? (
                <>
                  <Order2 />
                </>
              ) : (
                <></>
              )}
              {State === "Order3" ? (
                <>
                  <Order3 />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
