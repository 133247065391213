import React, { useState, useEffect } from 'react'
// import { NavLink } from 'react-router-dom';
import imk from "./Imgage/1.png"


const Social = () => {


    const [name,setName]= useState();
    const [name1,setName1]= useState();
    const [name2,setName2]= useState();
    const [name3,setName3]= useState();
    const [name4,setName4]= useState();
    const [name5,setName5]= useState();
    const [name6,setName6]= useState();
   
const Update =()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/social", requestOptions)
  .then(response => response.json())
  .then(result => {

      console.log(result,"lnkjlhkjhjklhkh")
      if(result.status=="001"){
          setName(result.data.Referral_Royalty);
          setName1(result.data.Social_Connector);
          setName2(result.data.Invite_Dynamo);
          setName3(result.data.Share_Master);
          setName4(result.data.Alliance_Architect);
          setName5(result.data.Code_Champion);
          setName6(result.data.Bonus_Benefactor);
    }
    else{

    }
  })
  .catch(error => console.log('error', error));

}

useEffect(()=>{
    
    Update();

},[])


    return (
        <>

        

            <div className="container" style={{paddingBottom:'100px'}}>
                <div className="row">
                    <div className='col-12 mt-4'>  
                        <div  style={{height:'80vh',overflowY:'scroll'}}>
                        <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
                        <div className='card-body py-0 py-2 '>
                        <div className='row'>
                        <div className='col-3'>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
                        </div>
                        </div> 

                        <div className='col-5'>
                        <div style={{lineHeight:'8px'}}>
                        <h6>Referral_Royalty </h6>
                        <p style={{color:'#8d8d8d'}}>  6 friends</p>
                        </div>
                        </div> 

                        <div className='col-4 '>
                        <div style={{lineHeight:'0px'}}>
                        <h6 style={{ borderRadius:'8px', padding:'5px 0px',textAlign:'center',

                            backgroundColor:name=="pending"?"lightgray":name=="active"?"#d2ffd2":name=="complete"?"#adffb6":'#000', 
                            color:name=="pending"?"#545454":name=="active"?"green":name=="complete"?"#063700":"#000"}}>{name}</h6> 

                           <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
                        </div>  
                        </div>

                        </div>
                        </div>
                        </div>   
                        
                        <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
                        <div className='card-body py-0 py-2 '>
                        <div className='row'>
                        <div className='col-3'>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
                        </div>
                        </div> 

                        <div className='col-5'>
                        <div style={{lineHeight:'8px'}}>
                        <h6>Social_Connector </h6>
                        <p style={{color:'#8d8d8d'}}>  15 friends</p>
                        </div>
                        </div> 

                        <div className='col-4 '>
                        <div style={{lineHeight:'0px'}}>
                        <h6 style={{    borderRadius:'8px', padding:'5px 0px',textAlign:'center',

                    backgroundColor:name1=="pending"?"lightgray":name1=="active"?"#d2ffd2":name1=="complete"?"#adffb6":'#000', 
                    color:name1=="pending"?"#545454":name1=="active"?"green":name1=="complete"?"#063700":"#000"


                    }}>{name1}</h6>
                        <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
                        </div>  
                        </div>

                        </div>
                        </div>
                        </div>  

                        






                        
                        <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
                        <div className='card-body py-0 py-2 '>
                        <div className='row'>
                        <div className='col-3'>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
                        </div>
                        </div> 

                        <div className='col-5'>
                        <div style={{lineHeight:'8px'}}>
                        <h6> Invite_Dynamo </h6>
                        <p style={{color:'#8d8d8d'}}>  30 friends</p>
                        </div>
                        </div> 

                        <div className='col-4 '>
                        <div style={{lineHeight:'0px'}}>
                        <h6 style={{   borderRadius:'8px', padding:'5px 0px',textAlign:'center',

backgroundColor:name2=="pending"?"lightgray":name2=="active"?"#d2ffd2":name2=="complete"?"#adffb6":'#000', 
color:name2=="pending"?"#545454":name2=="active"?"green":name2=="complete"?"#063700":"#000"}}>{name2}</h6>
                        <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
                        </div>  
                        </div>

                        </div>
                        </div>
                        </div>  

                        
                        <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
                        <div className='card-body py-0 py-2 '>
                        <div className='row'>
                        <div className='col-3'>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
                        </div>
                        </div> 

                        <div className='col-5'>
                        <div style={{lineHeight:'8px'}}>
                        <h6>  Share_Master </h6>
                        <p style={{color:'#8d8d8d'}}>  100 friends</p>
                        </div>
                        </div> 

                        <div className='col-4 '>
                        <div style={{lineHeight:'0px'}}>
                        <h6 style={{  borderRadius:'8px', padding:'5px 0px',textAlign:'center',

                backgroundColor:name3=="pending"?"lightgray":name3=="active"?"#d2ffd2":name3=="complete"?"#adffb6":'#000', 
                color:name3=="pending"?"#545454":name3=="active"?"green":name3=="complete"?"#063700":"#000"}}>{name3}</h6>
                
                <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
                        </div>  
                        </div>

                        </div>
                        </div>
                        </div>  

                        
                        <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
                        <div className='card-body py-0 py-2 '>
                        <div className='row'>
                        <div className='col-3'>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
                        </div>
                        </div> 

                        <div className='col-5'>
                        <div style={{lineHeight:'8px'}}>
                        <h6> Alliance_Architect </h6>
                        <p style={{color:'#8d8d8d'}}>  300 friends</p>
                        </div>
                        </div> 

                        <div className='col-4 '>
                        <div style={{lineHeight:'0px'}}>
                        <h6 style={{borderRadius:'8px', padding:'5px 0px',textAlign:'center',

                        backgroundColor:name4=="pending"?"lightgray":name4=="active"?"#d2ffd2":name4=="complete"?"#adffb6":'#000', 
                        color:name4=="pending"?"#545454":name4=="active"?"green":name4=="complete"?"#063700":"#000"}}>{name4}</h6>


                        <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
                        </div>  
                        </div>

                        </div>
                        </div>
                        </div>  
                        
                        <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
                        <div className='card-body py-0 py-2 '>
                        <div className='row'>
                        <div className='col-3'>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
                        </div>
                        </div> 

                        <div className='col-5'>
                        <div style={{lineHeight:'8px'}}>
                        <h6> Code_Champion </h6>
                        <p style={{color:'#8d8d8d'}}>  500 friends</p>
                        </div>
                        </div> 

                        <div className='col-4 '>
                        <div style={{lineHeight:'0px'}}>
                        <h6 style={{borderRadius:'8px', padding:'5px 0px',textAlign:'center',

                        backgroundColor:name5=="pending"?"lightgray":name5=="active"?"#d2ffd2":name5=="complete"?"#adffb6":'#000', 
                        color:name5=="pending"?"#545454":name5=="active"?"green":name5=="complete"?"#063700":"#000"}}>{name5}</h6>
                        <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
                        </div>  
                        </div>

                        </div>
                        </div>
                        </div>  
                        
                        <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
                        <div className='card-body py-0 py-2 '>
                        <div className='row'>
                        <div className='col-3'>
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
                        </div>
                        </div> 

                        <div className='col-5'>
                        <div style={{lineHeight:'8px'}}>
                        <h6> Bonus_Benefactor </h6>
                        <p style={{color:'#8d8d8d'}}>  1000 friends</p>
                        </div>
                        </div> 

                        <div className='col-4 '>
                        <div style={{lineHeight:'0px'}}>
                        <h6 style={{borderRadius:'8px', padding:'5px 0px',textAlign:'center',

                        backgroundColor:name6=="pending"?"lightgray":name6=="active"?"#d2ffd2":name6=="complete"?"#adffb6":'#000', 
                        color:name6=="pending"?"#545454":name6=="active"?"green":name6=="complete"?"#063700":"#000"}}>{name6}</h6>
                       
                        <progress id="file" value="30" max="100" style={{width:'100%'}}> 100% breaker </progress>
                        </div>  
                        </div>

                        </div>
                        </div>
                        </div>  

                    </div>
                </div>
            </div>
            </div>

        </>
    )
}

export default Social


// {
        //     res.status==="001"?"success":res.status==="002"?"complete":res.status==="003"?"panding":"panding"
        // }