 
import React from 'react';
import './so.css';
import { BiTimeFive } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import img from "../component/Imgage/card.jpeg";


const So = () => {


   return (
    <>
    <section className='VcBg' >
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <p className='VcP1'>Unleash Your Potential <br/>with the Iqube Personal Dev....</p>
                    <div className='d-flex justify-content-between'>
                        <p className='VcP2'><span><BiTimeFive style={{fontSize:"20px"}}/></span>8 days ago</p>
                        <p className='VcP3'><span><AiOutlineEye style={{fontSize:"20px"}}/></span>176,081 Views</p>
                        <button className='VcBtn'>Read More</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="card mt-2 pb-5" style={{width:"100%",height:"50vh",overflowX:"scroll"}}>
            <div className="card-body">
                <p className='VcP4'>News Feed</p>
                <div className="card" style={{width:"100%",borderRadius:"25px",borderBottom:"5px solid #C0C0C0"}}>
                    <div className="container" style={{borderBottom:300}}>
                    <div className="row">
                      <div className="col-md-4 col-3">
                        <img style={{width:"100%",height:"15vh",borderRadius:"30%"}} src={img} className="pt-3 py-3" alt="..."/>
                      </div>
                      <div className="col-md-8 col-8">
                            <p className='VcP5 mt-3'>The Iqube Network is Live on Product Hunt!</p>
                            <div className='d-flex justify-content-between'>
                                <p className='VcP6'><span><BiTimeFive style={{fontSize:"20px"}}/></span>a months ago</p>
                                <p className='VcP7'><span><AiOutlineEye style={{fontSize:"20px"}}/></span>176,081 Views</p>
                            </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-3">
                        <img style={{width:"100%",height:"15vh",borderRadius:"30%"}} src={img} className="pt-3 py-3" alt="..."/>
                      </div>
                      <div className="col-md-8 col-8">
                            <p className='VcP5 mt-3'>The Iqube Network is Live on Product Hunt!</p>
                            <div className='d-flex justify-content-between'>
                                <p className='VcP6'><span><BiTimeFive style={{fontSize:"20px"}}/></span>a months ago</p>
                                <p className='VcP7'><span><AiOutlineEye style={{fontSize:"20px"}}/></span>176,081 Views</p>
                            </div>
                      </div>
                    </div>
                    </div>
                </div>
                <div className="card mt-2" style={{width:"100%",borderRadius:"25px",borderBottom:"5px solid #C0C0C0"}}>
                    <div className="container">
                    <div className="row">
                      <div className="col-md-4 col-3">
                        <img style={{width:"100%",height:"15vh",borderRadius:"30%"}} src={img} className="pt-3 py-3" alt="..."/>
                      </div>
                      <div className="col-md-8 col-8">
                            <p className='VcP5 mt-3'>The Iqube Network is Live on Product Hunt!</p>
                            <div className='d-flex justify-content-between'>
                                <p className='VcP6'><span><BiTimeFive style={{fontSize:"20px"}}/></span>a months ago</p>
                                <p className='VcP7'><span><AiOutlineEye style={{fontSize:"20px"}}/></span>176,081 Views</p>
                            </div>
                      </div>
                    </div>
                    </div>
                </div>
                <div className="card mt-2" style={{width:"100%",borderRadius:"25px",borderBottom:"5px solid #C0C0C0"}}>
                    <div className="container">
                    <div className="row">
                      <div className="col-md-4 col-3">
                        <img style={{width:"100%",height:"15vh",borderRadius:"30%"}} src={img} className="pt-3 py-3" alt="..."/>
                      </div>
                      <div className="col-md-8 col-8">
                            <p className='VcP5 mt-3'>The Iqube Network is Live on Product Hunt!</p>
                            <div className='d-flex justify-content-between'>
                                <p className='VcP6'><span><BiTimeFive style={{fontSize:"20px"}}/></span>a months ago</p>
                                <p className='VcP7'><span><AiOutlineEye style={{fontSize:"20px"}}/></span>176,081 Views</p>
                            </div>
                      </div>
                    </div>
                    </div>
                </div>
                <div className="card mt-2" style={{width:"100%",borderRadius:"25px",borderBottom:"5px solid #C0C0C0"}}>
                    <div className="container">
                    <div className="row">
                      <div className="col-md-4 col-3">
                        <img style={{width:"100%",height:"15vh",borderRadius:"30%"}} src={img} className="pt-3 py-3" alt="..."/>
                      </div>
                      <div className="col-md-8 col-8">
                            <p className='VcP5 mt-3'>The Iqube Network is Live on Product Hunt!</p>
                            <div className='d-flex justify-content-between'>
                                <p className='VcP6'><span><BiTimeFive style={{fontSize:"20px"}}/></span>a months ago</p>
                                <p className='VcP7'><span><AiOutlineEye style={{fontSize:"20px"}}/></span>176,081 Views</p>
                            </div>
                      </div>
                    </div>
                    </div>
                </div>
                <div className="card mt-2" style={{width:"100%",borderRadius:"25px",borderBottom:"5px solid #C0C0C0"}}>
                    <div className="container">
                    <div className="row">
                      <div className="col-md-4 col-3">
                        <img style={{width:"100%",height:"15vh",borderRadius:"30%"}} src={img} className="pt-3 py-3" alt="..."/>
                      </div>
                      <div className="col-md-8 col-8">
                            <p className='VcP5 mt-3'>The Iqube Network is Live on Product Hunt!</p>
                            <div className='d-flex justify-content-between'>
                                <p className='VcP6'><span><BiTimeFive style={{fontSize:"20px"}}/></span>a months ago</p>
                                <p className='VcP7'><span><AiOutlineEye style={{fontSize:"20px"}}/></span>176,081 Views</p>
                            </div>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</>
   )
 }
 
 export default So
 