import React from 'react';
import './App.css';
import Home from './component/Home.jsx';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import Footer from './component/Footer';
import User from './component/User';  
import So from './component/So';
import Min from "./component/Min";
import View from "./component/View";
import Profile from "./component/Profile";
import Login from './component/Login';
import CheckOtp from './component/CheckOtp';
import Sno from './component/Sno';
import Sign from './component/Sign';
import Progressbar from './component/Progressbar'; 
import { AuthRoute } from './AuthRoute';
import Stacking from './Stacking';


function App() {
  const {pathname} = useLocation();
  return (
    <>
      <Routes>
        <Route exact path='/otp' element={<CheckOtp/>}/>
        <Route exact path='/login' element={<Login/>}/>
        <Route path='Sign' element={<Sign />} />
        <Route path='/' element={<AuthRoute/>} >
        <Route index element={<Home />} />
        <Route path='User' element={<User />} />
        <Route path='So' element={<So />} />
        <Route path='Min' element={<Min />} />
        <Route path='View' element={<View />} />
        <Route path='Profile' element={<Profile />} />
        <Route path='Stacking' element={<Stacking />} />
        <Route path='Sno' element={<Sno />} />
        <Route path='Footer' element={<Footer />} />
        <Route path='Progressbar' element={<Progressbar />} />
        </Route>
      </Routes>
     {
      (pathname === '/login' || pathname === '/otp')   ?<></>: <div className='footer' >
      <Footer />
    </div>
     }
      </>
   
  );
}
export default App;
