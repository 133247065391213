import { useEffect, useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CheckOtp = () => {
  const navigate = useNavigate();
  const [otp1, setOtp1] = useState();
  const [OTP, setOTP] = useState("");

  // const [otp2, setOtp2] = useState();
  // const [otp3, setOtp3] = useState();
  // const [otp4, setOtp4] = useState();
  const [numbers, setNumbers] = useState({});


  
  const validateOtp = () => {
    var formdata = new FormData();
    formdata.append("email", localStorage.getItem("cred_email"));
    formdata.append("otp", OTP);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://swamivivekanandshikshasevasansthan.in/api/checkotp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          localStorage.setItem("token",result.token)
          localStorage.removeItem("cred_email");
          navigate("/");
          console.log(result);
        } else {
          toast(result.message)
          console.log(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNumbers({
      ...numbers,
      [name]: value,
    });
  };
  

 
  return (
    <>
    <ToastContainer/>
      <div className="container1">
        <div className="screen">
          <div className="screen__content">
            <div className="login">
              <label htmlFor="">Enter Otp</label>
              {/* <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
                className="login__field"
              > 
                <input
                  name="0"
                  type="text"
                  onChange={(e) => setOtp1(e.target.value)}
                  className="login__input"
                />
                <input
                  name="1"
                  type="text"
                  onChange={(e) => setOtp2(e.target.value)}
                  className="login__input"
                />
                <input
                  name="2"
                  type="text"
                  onChange={(e) => setOtp3(e.target.value)}
                  className="login__input"
                />
                <input
                  name="3"
                  type="text"
                  onChange={(e) => setOtp4(e.target.value)}
                  className="login__input"
                />
              </div> */} 





              <div className='my-4' style={{ display:'flex', justifyContent:'center'}}>
      <OTPInput style={{width:"100%"}}  inputStyles={{width:"25%",border:"1px solid #CBCBCB",borderRadius:"12px", height:'50px'}} value={OTP} onChange={setOTP}  OTPLength={4} otpType="number" disabled={false}  /> 
      {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
        </div>
   














              
              <button
                onClick={() =>  validateOtp(OTP)}
                className="button login__submit"
              >
                <span  className="button__text">Validate Otp</span>
                {/* <i className="button__icon fas fa-chevron-right"></i> */}
              </button>
            </div>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOtp;
