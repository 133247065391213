import { useEffect, useState } from 'react';
import './home.css';
import { FaUserFriends } from "react-icons/fa";
import Roadmap from './Roadmap';
import Header from './Header';
import { Toast } from 'bootstrap';
import  ProgressBar  from './Progressbar';
import Footer from './Footer';
import ReactCountryFlag from 'react-country-flag';
// import { IconName } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie-player' 
import Reff from '../component/Imgage/referral.json'
import { AiOutlineInstagram } from 'react-icons/ai';

import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';


const Home = () => {
    const [count1, setCount1] = useState(0);
      const [count2, setCount2] = useState();
      const [miningRate,setMiningRate] = useState(0.052);
      const [countDigit, setCountDigit] = useState();
      const [countFloat, setCountFloat] = useState();
      const [phase, setPhase] = useState(1);
      const [teir1,setTeir1] = useState(0)
      const [teir2,setTeir2] = useState(0)
      const [active_ref,setActiveRef] = useState(0)
      const [total_ice_count,setTotalIceCount] = useState();
      const [name,setName]= useState(0);
      const [Status, setStatus] = useState();
      const [Level, setLevel] = useState(); 
      const [ level_data ,setLevelData] = useState([])
      const [loading,setloading] = useState(false);
      const [count,setCount] = useState();
      const [count3,setCount3] = useState();
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      console.log(count1,count2,miningRate);
      // const getCountDigitValue = () => {
      //   return countDigit;
      // };
      
      // const getCountFloatValue = () => {
      //   return countFloat;
      // };

      // const intVal = getCountDigitValue();

      

    const getfirstLevel = () => {
            setloading(true)
            var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("http://swamivivekanandshikshasevasansthan.in/api/userlevel2", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result, "hello")
            setLevelData(result.data)
          })
          .catch(error => console.log('error', error))
          .finally(()=>{setloading(false)})
          };
         

    const Rank =()=>{
              var formdata = new FormData();
          formdata.append("token", localStorage.getItem("token"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          fetch("http://swamivivekanandshikshasevasansthan.in/api/rank", requestOptions)
            .then(response => response.json())
            .then(result => {
              if(result.status=="001"){
                setCount(result.data)
                setCount3(result.data1)
              }
            })
            .catch(error => console.log('error', error));
            }
                    
    useEffect(()=>{
      miningInfo();
      getfirstLevel()
      Rank();
    },[])

      

    // useEffect(() => {
    //     let integerPart = name;
    //     let decimalPart = 0;
    
    //     const updateDecimal = () => {
    //       decimalPart += 0.01;
    //       if (decimalPart >= 1) {
    //         decimalPart = 0;
    //         integerPart++;
    //       }
    //       updateDisplay();
    //     }
    
    //     const updateDisplay = () => {
    //   const d1 = document.getElementById("d1");
    //   const d2 = document.getElementById("d2");
    //   d1.innerText = integerPart.toFixed(0)+"."
    //   d2.innerText  = decimalPart.toFixed(2).substr(1).split(".").join("");
    //       // const valueDisplay = document.getElementById("valueDisplay");
    //       // valueDisplay.textContent = integerPart.toFixed(0) + decimalPart.toFixed(2)//substr(1);
    //     };
    
    //     const interval = setInterval(updateDecimal, 2000); // Update every second
    //     updateDisplay(); // Initial display
    
    //     return () => clearInterval(interval);
    //   }, []);
    

    
      useEffect(() => { 
       // setCount1(localStorage.getItem('value1'))
        //getCoinCountDetials(); ok
        
        const interval = setInterval(() => {
          if (count1 <1000) {
            //setCount1(localStorage.getItem('value1'))
            setCount1(prevCount => prevCount + miningRate);
           // localStorage.setItem('value1',count1);
          } else if (phase === 1) {
            setPhase(2);
          } else {
            setCount2(prevCount => prevCount + 1);
            //localStorage.setItem("value", count2);
           // miningInfo();
            //console.log(count2);
            setCount1(0); 
          }
          
        }, 30000);// Increase count every 1000ms (1 second)
    
        return () => {
          clearInterval(interval); // Clear the interval when the component unmounts
        };
      }, [count1, count2, phase]);



      const miningInfo = () => { 

        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
      
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      
      fetch("http://swamivivekanandshikshasevasansthan.in/api/miningInfo", requestOptions)
        .then(response => response.json())
        .then(result => { 
          if(result.status==1){
            setStatus(1);
            //  console.log(JSON.parse(result.coin.split(".",3,3)));
             // setCount1(JSON.parse(result.coin.split(".",2)));
            // console.log(result.coin.toString().split('.'))
              const parts = result.coin.toString().split('.');
              console.log(parts[0]);
              const integerPart = parseInt(parts[0]);
              const fractionalPart = parseFloat(`${parts[1] || 0}`);
              setCount1(parseInt(fractionalPart / 1000));
              setCount2(integerPart);
        
        
              const parts1 = result.second_rate.toString().split('.');
              console.log(parts1[0]);
              const integerPart1 = parseInt(parts1[0]);
              const fractionalPart1 = parseFloat(`${parts1[1] || 0}`);
              console.log(result.second_rate.split(""));
              setMiningRate(fractionalPart1);
              // setCount2(JSON.parse(result.coin.split(".",1)));
              console.log(result,"successfully done api");
          }
          else{
                setStatus(0);
                setShow(true);
                console.log(result,"hello ");
          }
        })
        .catch(error => console.log('error ', error));
      }

   const ApiStart = ()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://swamivivekanandshikshasevasansthan.in/api/startMining", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if(result.status==1){
          miningInfo();
        console.log(result)
      }
    })
      .catch(error => console.log('error', error));
   }



   

 
  return (
    <>

<div style={{ backgroundColor: '#f8f9fa', }} >


      <Header />


    </div>

    

    <div className='container-fulid' style={{overflowX:'hidden'}}>
      <div className='row'>
        <div className='col-12'>
        <div className="container" style={{ borderRadius: '20px 20px 0px 0px', height: '30%', backgroundImage: 'url("img/1.jpg")' ,backgroundSize:'content'}}>
      <div className="row">
        <div className="col-sm-12">
          <div>
            <div id="demo" style={{ padding: '10px', color: 'black', height: '50%', }}>
              <div id="valueDisplay" style={{  color: '#fff', paddingBottom: '10px', marginTop: '10px',display:"flex", justifyContent:'center', paddingBottom:'40px' }}>
             {
              Status==0?<><div class="" id="d1" style={{display:'flex', justifyContent:'center'}}>
              <button onClick={()=>{ApiStart()}}  style={{fontSize:'20px',fontWeight:"600",
               borderRadius:'20px', border:' 1px solid gray', padding:'4px 20px', backgroundColor:'#c7e2bc', color:'green',width:'100%'}}>Start Mining</button>
              </div></>:<><div class="" id="d1" style={{fontSize:'23px',fontWeight:"600"}}>
              {count2}
              </div>
              <div  id="d2" style={{fontSize:'16px',marginBottom:"20px"}}>.{count1}</div></>
             } 
               </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
       
    <div className="container" style={{ backgroundColor: 'white', borderRadius: '30px 30px 0px 0px', height: '100px', position: 'absolute', marginTop: '-26px'  }}>
      <div className="row">
        <div className="col-sm-12">
          <div style={{ color: '#1D46C2', fontSize: '40' }}>
            <h5 style={{ padding: 20 }}> OVERVIEW </h5>
          </div>
        </div>
      </div>
    </div>


    <section id="slider" style={{ marginTop: '38px', }}>
      <input type="radio" name="slider" id="s1" />
      <input type="radio" name="slider" id="s3" defaultChecked />
      <input type="radio" name="slider" id="s5" />
      <label htmlFor="s1" id="slide1">
        <div className="container" style={{backgroundColor:'black',borderRadius:'20px'}}>
          <div className="row">
            <div className="col-lg-10 col-md-6 col-sm-6 col-7 m-auto" style={{ color: '#ffffff' }}>
              <h2>
                Iman
              </h2>
 
                <Lottie 
                  loop
                  animationData={Reff}
                  play
                  style={{ width:'100%', height:'auto' }}
                />
              {/* <p className="mt-4 text-nowrap" style={{ fontSize: '12px' }}> REFERRALAS</p>
              <h1 style={{ marginTop: '-10px', color:'#fff'}}> {Level} </h1> */}
            </div>
            
            {/* <p style={{ color: '#eee' }}> Refer more users and earn more iqube .</p> */}
          </div>
        </div>
      </label>
      <label htmlFor="s3" id="slide3">
        <div className="container"  style={{backgroundColor:'black',borderRadius:'20px'}}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ color: '#ffffff' }}>
              <h2>
                Iman
              </h2>
              <p className="mt-4 text-nowrap" style={{ fontSize: '12px' }}> REFERRALAS</p>
              <h1 style={{ marginTop: '-10px' }}>   {count3} </h1>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ color: '#ffffff' }}>
              <p style={{ float: 'right' }}>
                 
              </p>
              <p className="mt-5 text-nowrap" style={{ fontSize: '12px' }}> GLOBAL RANK </p>
              <h1 style={{ marginTop: '-10px' }}>   {count} </h1>
            </div>
            <p style={{ color: '#eee' }}> Refer more users and earn more iqube .</p>
          </div>
        </div>
      </label> 
      <label htmlFor="s5" id="slide5" className='pb-4'>
        <div className="container pb-4"  style={{backgroundColor:'black',borderRadius:'20px'}}>
        <div className="row " style={{ overflowX:"scroll"}}>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ color: '#ffffff' }}>
              <h3>Iman  </h3>
              {/* <p className="mt-4 text-nowrap" style={{ fontSize: '12px' }}> REFERRALAS</p> */}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ color: '#ffffff' }}>
              <p  style={{ float: 'right' }}>
               
              </p>
            </div>
            {level_data?.map((item)=>{
          return(
            <div style={{display:"flex", justifyContent:"space-around",lineHeight:'19px'}}>

            <p style={{color:"white", padding:'0%'}}>{item?.user_name}</p>
            <ReactCountryFlag countryCode={item?.country}  svg  style={{fontSize:'22px'}}
                title={item?.country}/>
            </div>
          )
         })}
          </div>

          
        
        </div>
      </label>
    </section>
    </div>
      </div>
    </div>


    <section style={{
      overflowY:"scroll"
    }}>
     <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
        <div className="card" style={{ width: "100%", borderRadius: "25px", borderBottom: "5px solid #C0C0C0", borderTop: "3px solid #C0C0C0" , backgroundColor: '#12317E',color: '#eee'}}>
        <div className="container"  style={{backgroundColor:'black',borderRadius:'20px'}}>
          <div className="row">
            <div className="col-md-4 col-3">
            <span style={{fontSize: '50px'}}><FaUserFriends/></span>
            </div>
            <div className="col-md-8 col-8">
            <h3 className='mt-2'> invite Friend</h3>
              <p className='mt-3' style={{fontSize:"12px"}}>The Iqube Network is Live on Product Hunt!</p>
            </div>
          </div>

        </div>
      </div>
        </div>
      </div>
     </div>



 
     <div className='container my-5 mb-5'>
      <div className='row'>
        <div className='col-lg-12 col-smm-12'>
      
        <div className='mb-5 d-flex'  >

           <NavLink to="https://www.instagram.com/iqubenetworks/"><AiOutlineInstagram style={{fontSize:'30px', }}/></NavLink>  <h6 className='ps-3'>instagram follow us on</h6><br/><br/>
           </div>
      
        <div className='mb-5 d-flex'  >

           <NavLink to="https://twitter.com/I_Qube_Network"><AiOutlineTwitter style={{fontSize:'30px', }}/></NavLink>  <h6 className='ps-3'>twitter follow us on</h6><br/><br/>
           </div>
      
        <div className='mb-5 d-flex'  >

           <NavLink to="https://www.linkedin.com/company/i-qube-networks/?viewAsMember=true"><AiFillLinkedin style={{fontSize:'30px', }}/></NavLink>  <h6 className='ps-3'>linkedin follow us on</h6><br/><br/>
           </div>



            
          

        </div>
      </div>
     </div>

    </section>




    

 

  </>
  )
}

export default Home
