import { useEffect, useState } from 'react'
import { BsFillGridFill } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import i from "./Imgage/1.png"
import p from "./Imgage/as.jpg"; 
import { NavLink, useNavigate } from 'react-router-dom';


const Header = () => {
const [profile,setProfile] = useState();
const navigation = useNavigate();
  const getProfile = ()=>{
    var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/profile", requestOptions)
  .then(response => response.json())
  .then(result =>{
   if(result.status == 1){
    setProfile(result.data);
   }
  })
  .catch(error => console.log('error', error));
   }

const logout = () => {
var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/Logout", requestOptions)
  .then(response => response.json())
  .then(result =>{ console.log(result)
  if (result.status == "001") {
    console.log(result,"Ada tere ada")
    localStorage.removeItem("token")
    navigation("/Sign")
  }
  })
  .catch(error => console.log('error', error));
   }

   useEffect(()=>{
    getProfile()
   },[])


  return(
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light ">
<div className="container-fluid">
<div classNameName='d-flex' style={{
  justifyContent: 'center',
  alignItems:"center"
}}>
<div className='d-flex'>
<img src={p} style={{height:'60px'}}/> 
 <h6 className='mt-4'>{profile?.user_name}</h6>
</div>
</div>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{border:'none'}}>
    <BsFillGridFill/> 
  </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      <li className="nav-item">
        <NavLink className="nav-link active" aria-current="page" to="/Profile">profile</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link active" aria-current="page" to="/Stacking">Stacking</NavLink>
      </li>
      <li className="nav-item">
        <span className="" href="#" style={{textAlign:"center"}} onClick={logout} > <AiOutlineLogout/>  Log Out</span>
      </li>
    </ul>
  </div>
</div>
</nav>
    </div>
  )
}

export default Header
