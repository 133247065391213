
import { NavLink, useNavigate } from 'react-router-dom';
import './login.css';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
const Login = () => {
    const navigate = useNavigate()
    const [creds,setCreds] = useState({});

    const handleChange = (e) => {
        const{name,value} = e.target; 
        setCreds({
        ...creds,
        [name]:value
    })}

   const handleSendOtp =(creds)=>{

    var formdata = new FormData();
    Object.entries(creds).map(([key,value])=>formdata.append(key,value))
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("http://swamivivekanandshikshasevasansthan.in/api/signup", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status == 1){
            localStorage.setItem("cred_email",creds.email)
        navigate("/otp")
        toast(result.message)
        }else{
            toast(result.message)
        }
      })
      .catch(error => console.log('error', error));
   }


    return (
       <>
       <ToastContainer/>
       <div className="container1">
	<div className="screen">
		<div className="screen__content">
			<div className="login">
            {/* <label htmlFor="">Mobile</label> */}
				<div className="login__field">
             
					{/* <i className="login__icon fas fa-user"></i> */}
					<input name='email' type="text" onChange={handleChange} className="login__input" placeholder="Email"/>
				</div>
                {/* <label htmlFor="">Referel Id</label> */}
				<div className="login__field">
                
					{/* <i className="login__icon fas fa-lock"></i> */}
					<input name='refer_id' type="text" onChange={handleChange} className="login__input" placeholder="Referel Id"/>

                    <select class="form-select mt-4 " id="country" name="country"
                     style={{width:"75%"}}>

                     <input type='text' placeholder='Search...'/>

    <option className='mt-3'>select country</option>
    <option value="Afghanistan">Afghanistan</option>
    <option value="AlandIslands">Aland Islands</option>
    <option value="Albania">Albania</option>
    <option value="Algeria">Algeria</option>
    <option value="AmericanSamoa">American Samoa</option>
    <option value="Andorra">Andorra</option>
    <option value="Angola">Angola</option>
    <option value="Anguilla">Anguilla</option>
    <option value="Antarctica">Antarctica</option>
    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
    <option value="Argentina">Argentina</option>
    <option value="Armenia">Armenia</option>
    <option value="Aruba">Aruba</option>
    <option value="Australia">Australia</option>
    <option value="Austria">Austria</option>
    <option value="Azerbaijan">Azerbaijan</option>
    <option value="Bahamas">Bahamas</option>
    <option value="Bahrain">Bahrain</option>
    <option value="Bangladesh">Bangladesh</option>
    <option value="Barbados">Barbados</option>
    <option value="Belarus">Belarus</option>
    <option value="Belgium">Belgium</option>
    <option value="Belize">Belize</option>
    <option value="Benin">Benin</option>
    <option value="Bermuda">Bermuda</option>
    <option value="Bhutan">Bhutan</option>
    <option value="Bolivia">Bolivia</option>
    <option value="Bonaire">Bonaire, Sint Eustatius and Saba</option>
    <option value="Bosnia">Bosnia and Herzegovina</option>
    <option value="Botswana">Botswana</option>
    <option value="BouvetIsland">Bouvet Island</option>
    <option value="Brazil">Brazil</option>
    <option value="British">British Indian Ocean Territory</option>
    <option value="BruneiDarussalam">Brunei Darussalam</option>
    <option value="Bulgaria">Bulgaria</option>
    <option value="BurkinaFaso">Burkina Faso</option>
    <option value="Burundi">Burundi</option>
    <option value="Cambodia">Cambodia</option>
    <option value="Cameroon">Cameroon</option>
    <option value="Canada">Canada</option>
    <option value="CapeVerde">Cape Verde</option>
    <option value="CaymanIslands">Cayman Islands</option>
    <option value="Central">Central African Republic</option>
    <option value="Chad">Chad</option>
    <option value="Chile">Chile</option>
    <option value="China">China</option>
    <option value="ChristmasIsland">Christmas Island</option>
    <option value="Islands">Cocos (Keeling) Islands</option>
    <option value="Colombia">Colombia</option>
    <option value="Comoros">Comoros</option>
    <option value="Congo">Congo</option>
    <option value="Congo">Congo, Democratic Republic of the Congo</option>
    <option value="Islands">Cook Islands</option>
    <option value="Costa">Costa Rica</option>
    <option value="Cote">Cote D'Ivoire</option>
    <option value="Croatia">Croatia</option>
    <option value="Cuba">Cuba</option>
    <option value="Curacao">Curacao</option>
    <option value="Cyprus">Cyprus</option>
    <option value="CzechRepublic">Czech Republic</option>
    <option value="Denmark">Denmark</option>
    <option value="Djibouti">Djibouti</option>
    <option value="Dominica">Dominica</option>
    <option value="Dominican">Dominican Republic</option>
    <option value="Ecuador">Ecuador</option>
    <option value="Egypt">Egypt</option>
    <option value="Salvador">El Salvador</option>
    <option value="EquatorialGuinea">Equatorial Guinea</option>
    <option value="Eritrea">Eritrea</option>
    <option value="Estonia">Estonia</option>
    <option value="Ethiopia">Ethiopia</option>
    <option value="FalklandIslands">Falkland Islands (Malvinas)</option>
    <option value="FaroeIslands">Faroe Islands</option>
    <option value="Fiji">Fiji</option>
    <option value="Finland">Finland</option>
    <option value="France">France</option>
    <option value="FrenchGuiana">French Guiana</option>
    <option value="French">French Polynesia</option>
    <option value="French">French Southern Territories</option>
    <option value="Gabon">Gabon</option>
    <option value="Gambia">Gambia</option>
    <option value="Georgia">Georgia</option>
    <option value="Germany">Germany</option>
    <option value="Ghana">Ghana</option>
    <option value="Gibraltar">Gibraltar</option>
    <option value="Greece">Greece</option>
    <option value="Greenland">Greenland</option>
    <option value="Grenada">Grenada</option>
    <option value="Guadeloupe">Guadeloupe</option>
    <option value="Guam">Guam</option>
    <option value="Guatemala">Guatemala</option>
    <option value="Guernsey">Guernsey</option>
    <option value="Guinea">Guinea</option>
    <option value="Guinea-Bissau">Guinea-Bissau</option>
    <option value="Guyana">Guyana</option>
    <option value="Haiti">Haiti</option>
    <option value="Heard Island ">Heard Island and Mcdonald Islands</option>
    <option value="Vatican City State">Holy See (Vatican City State)</option>
    <option value="Honduras">Honduras</option>
    <option value="HongKong">Hong Kong</option>
    <option value="Hungary">Hungary</option>
    <option value="Iceland">Iceland</option>
    <option value="India">India</option>
    <option value="Indonesia">Indonesia</option>
    <option value="Iran">Iran, Islamic Republic of</option>
    <option value="Iraq">Iraq</option>
    <option value="Ireland">Ireland</option>
    <option value="Isle">Isle of Man</option>
    <option value="Israel">Israel</option>
    <option value="Italy">Italy</option>
    <option value="Jamaica">Jamaica</option>
    <option value="Japan">Japan</option>
    <option value="Jersey">Jersey</option>
    <option value="Jordan">Jordan</option>
    <option value="Kazakhstan">Kazakhstan</option>
    <option value="Kenya">Kenya</option>
    <option value="Kiribati">Kiribati</option>
    <option value="Korea">Korea, Democratic People's Republic of</option>
    <option value="Korea">Korea, Republic of</option>
    <option value="Kosovo">Kosovo</option>
    <option value="Kuwait">Kuwait</option>
    <option value="Kyrgyzstan">Kyrgyzstan</option>
    <option value="Lao">Lao People's Democratic Republic</option>
    <option value="Latvia">Latvia</option>
    <option value="Lebanon">Lebanon</option>
    <option value="Lesotho">Lesotho</option>
    <option value="Liberia">Liberia</option>
    <option value="Libyan">Libyan Arab Jamahiriya</option>
    <option value="Liechtenstein">Liechtenstein</option>
    <option value="Lithuania">Lithuania</option>
    <option value="Luxembourg">Luxembourg</option>
    <option value="Macao">Macao</option>
    <option value="Macedonia">Macedonia, the Former Yugoslav Republic of</option>
    <option value="Madagascar">Madagascar</option>
    <option value="Malawi">Malawi</option>
    <option value="Malaysia">Malaysia</option>
    <option value="Maldives">Maldives</option>
    <option value="Mali">Mali</option>
    <option value="Malta">Malta</option>
    <option value="Islands">Marshall Islands</option>
    <option value="Martinique">Martinique</option>
    <option value="Mauritania">Mauritania</option>
    <option value="Mauritius">Mauritius</option>
    <option value="Mayotte">Mayotte</option>
    <option value="Mexico">Mexico</option>
    <option value="Micronesia">Micronesia, Federated States of</option>
    <option value="Moldova">Moldova, Republic of</option>
    <option value="Monaco">Monaco</option>
    <option value="Mongolia">Mongolia</option>
    <option value="Montenegro">Montenegro</option>
    <option value="Montserrat">Montserrat</option>
    <option value="Morocco">Morocco</option>
    <option value="Mozambique">Mozambique</option>
    <option value="Myanmar">Myanmar</option>
    <option value="Namibia">Namibia</option>
    <option value="Nauru">Nauru</option>
    <option value="Nepal">Nepal</option>
    <option value="Netherlands">Netherlands</option>
    <option value="Netherlands">Netherlands Antilles</option>
    <option value="Caledonia">New Caledonia</option>
    <option value="Zealand">New Zealand</option>
    <option value="Nicaragua">Nicaragua</option>
    <option value="Niger">Niger</option>
    <option value="Nigeria">Nigeria</option>
    <option value="Niue">Niue</option>
    <option value="Island">Norfolk Island</option>
    <option value="Islands">Northern Mariana Islands</option>
    <option value="Norway">Norway</option>
    <option value="Oman">Oman</option>
    <option value="Pakistan">Pakistan</option>
    <option value="Palau">Palau</option>
    <option value="Palestinian">Palestinian Territory, Occupied</option>
    <option value="Panama">Panama</option>
    <option value="Guinea">Papua New Guinea</option>
    <option value="Paraguay">Paraguay</option>
    <option value="Peru">Peru</option>
    <option value="Philippines">Philippines</option>
    <option value="Pitcairn">Pitcairn</option>
    <option value="Poland">Poland</option>
    <option value="Portugal">Portugal</option>
    <option value="PuertoRico">Puerto Rico</option>
    <option value="Qatar">Qatar</option>
    <option value="Reunion">Reunion</option>
    <option value="Romania">Romania</option>
    <option value="Russian">Russian Federation</option>
    <option value="Rwanda">Rwanda</option>
    <option value="Barthelemy">Saint Barthelemy</option>
    <option value="Helena">Saint Helena</option>
    <option value="Nevis">Saint Kitts and Nevis</option>
    <option value="Lucia">Saint Lucia</option>
    <option value="Martin">Saint Martin</option>
    <option value="Miquelon">Saint Pierre and Miquelon</option>
    <option value="Grenadines">Saint Vincent and the Grenadines</option>
    <option value="Samoa">Samoa</option>
    <option value="Marino">San Marino</option>
    <option value="Principe">Sao Tome and Principe</option>
    <option value="Arabia">Saudi Arabia</option>
    <option value="Senegal">Senegal</option>
    <option value="Serbia">Serbia</option>
    <option value="Montenegro">Serbia and Montenegro</option>
    <option value="Seychelles">Seychelles</option>
    <option value="Leone">Sierra Leone</option>
    <option value="Singapore">Singapore</option>
    <option value="Maarten">Sint Maarten</option>
    <option value="Slovakia">Slovakia</option>
    <option value="Slovenia">Slovenia</option>
    <option value="Islands">Solomon Islands</option>
    <option value="Somalia">Somalia</option>
    <option value="Africa">South Africa</option>
    <option value="Islands">South Georgia and the South Sandwich Islands</option>
    <option value="Sudan">South Sudan</option>
    <option value="Spain">Spain</option>
    <option value="Lanka">Sri Lanka</option>
    <option value="Sudan">Sudan</option>
    <option value="Suriname">Suriname</option>
    <option value="Mayen">Svalbard and Jan Mayen</option>
    <option value="Swaziland">Swaziland</option>
    <option value="Sweden">Sweden</option>
    <option value="Switzerland">Switzerland</option>
    <option value="Syrian">Syrian Arab Republic</option>
    <option value="China">Taiwan, Province of China</option>
    <option value="Tajikistan">Tajikistan</option>
    <option value="Tanzania">Tanzania, United Republic of</option>
    <option value="Thailand">Thailand</option>
    <option value="Timor">Timor-Leste</option>
    <option value="Togo">Togo</option>
    <option value="Tokelau">Tokelau</option>
    <option value="Tonga">Tonga</option>
    <option value="Tobago">Trinidad and Tobago</option>
    <option value="Tunisia">Tunisia</option>
    <option value="Turkey">Turkey</option>
    <option value="Turkmenistan">Turkmenistan</option>
    <option value="Islands">Turks and Caicos Islands</option>
    <option value="Tuvalu">Tuvalu</option>
    <option value="Uganda">Uganda</option>
    <option value="Ukraine">Ukraine</option>
    <option value="Emirates">United Arab Emirates</option>
    <option value="Kingdom">United Kingdom</option>
    <option value="States">United States</option>
    <option value="Islands">United States Minor Outlying Islands</option>
    <option value="Uruguay">Uruguay</option>
    <option value="Uzbekistan">Uzbekistan</option>
    <option value="Vanuatu">Vanuatu</option>
    <option value="Venezuela">Venezuela</option>
    <option value="Viet">Viet Nam</option>
    <option value="British">Virgin Islands, British</option>
    <option value="Islands">Virgin Islands, U.s.</option>
    <option value="Futuna">Wallis and Futuna</option>
    <option value="Sahara">Western Sahara</option>
    <option value="Yemen">Yemen</option>
    <option value="Zambia">Zambia</option>
    <option value="Zimbabwe">Zimbabwe</option>
</select>
				</div>
				<button onClick={()=>handleSendOtp(creds)} className="button login__submit">
					<span className="button__text" >Send Otp </span>
					{/* <i className="button__icon fas fa-chevron-right"></i> */}
				</button>	
                <p className='text-center'> I have an <NavLink to='/Sign' style={{textDecoration:'none', color:'blue', fontSize:'17px'}}>
                account 
                </NavLink>
                </p>			
			</div>
			
		</div>
		<div className="screen__background">
			<span className="screen__background__shape screen__background__shape4"></span>
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
       </div>
       </>
    );
};

export default Login;