import React from 'react';
import { FaHome } from 'react-icons/fa';
// import { BsFillPeopleFill } from 'react-icons/bs';
// import { BsFillLightbulbFill } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
// import { BsFillPersonFill } from 'react-icons/bs';
import '../component/footer.css'
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>

    <div className='container-fluid p-0 fixed-bottom'  >
        <div className='row'>
            <div className='col-lg-12'>
            <div> 

              {/* <nav className="circle_nav ">
              <a className="hamburger" style={{color: 'rgb(255, 255, 255)'}}><i className="fa fa-bars">    
              </i></a>
              
              </nav> */}


              <footer className="mx-0 px-0"> 
              <NavLink to="/"><FaHome style={{fontSize:'27px'}}/></NavLink>
              <NavLink to="/User">  <FaUsers style={{fontSize:'27px'}}/></NavLink>
              <NavLink to="/So"><i className="fa fa-bell-o" aria-hidden="true" style={{fontSize:'27px'}}></i></NavLink>
              <NavLink to="/Min"><i className="fa fa-bar-chart" aria-hidden="true" style={{fontSize:'27px'}}></i></NavLink>
              </footer>
              </div> 
            </div>
        </div>
    </div>
        

    </>
  );
}

export default Footer;
