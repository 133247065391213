import React, { forwardRef, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { WhatsApp } from '@mui/icons-material';
import { light } from '@mui/material/styles/createPalette';
import { skeletonClasses } from '@mui/material';
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import ReactCountryFlag from "react-country-flag"

const steps = [
  {
    label: 'Enter your nickname',
    description: `Enter your nickname`,
  },
  {
    label: 'Current City',
    description:
      'Enter your current city',
  },
  {
    label: 'Select country',
    description: `Select your country`,
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = useState("");
  // cont [loading,setLoading] = useState(false);s  
  const [city, setCity] = useState("");
  const [status, setStatus] = useState("");
  const [country, setCountry] = useState("");
console.log(name,city,country)
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow1(false);
  const handleShow2 = () => setShow1(true);

  const handleClose3 = () => setShow2(false);
  const handleShow3 = () => setShow2(true);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const demo = () => {
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://swamivivekanandshikshasevasansthan.in/api/achievements", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result, "hhh")
        if (result.status == "001") {
          setStatus(result.data);
        }
        else {
          // handleNext(); 
        }
      })
      .catch(error => console.log('error', error));

  }
  // const [update, setUpdate] = useState({ name: "", city: "", country: "" });


  const Update = () => {
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem('token'));
    // Object.entries(update).map(([key, value]) => formdata.append(key, value))

    formdata.append("name", name);
    formdata.append("city", city);
    formdata.append("country", country);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://swamivivekanandshikshasevasansthan.in/api/update", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == '001') {
          demo()
          toast(result.message)
          console.log(result, "lkmlkmlkm")
        } else {
          toast(result.message)
        }
      })
      .catch(error => console.log('error', error));
  }

  // const handleChange = (e) => {
  //   console.log(e.target.value, e.target.name)
  //   const { name, value } = e.target

  //   setUpdate({
  //     ...update,
  //     [name]: value
  //   })
  // }
  console.log(status.name,"status.name")

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (status.name === null) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleShow();
      // handleClose();

      // }
    } else if (status.city === null) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleShow2();
      // handleClose2();
      // return;
      //  }
    } else if (status.country === null) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      handleShow3();
      // handleClose3();
      // return;
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  };


  // console.log(city,name,country,'data get in ')
  useEffect(() => {
    //handleNext();
    demo();
    
  }, [])
  return (

    <div style={{ marginBottom: '66px' }}>
      <Box sx={{}}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.name}>
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Last step </Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            {/* <Typography>All steps completed - you&apos;re finished</Typography> */}
            {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button> */}
          </Paper>
        )}
      </Box>



      <Modal show={show} onHide={handleClose} className='mt-5 pt-5 my-5' style={{ backdropFilter: 'blur(4px)' }}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3">
            <label>Name</label>
            <input name='name' type="text" value={name} class="form-control" aria-describedby="emailHelp"
              onChange={(e)=>setName(e.target.value)}
            />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: 'blue', color: '#fff' }} onClick={() => { Update(); handleClose(); }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>



      {/* second step start */}


      <Modal show={show1} onHide={handleClose2} className='mt-5 pt-5 my-5' style={{ backdropFilter: 'blur(4px)' }}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3">
            <label for="formGroupExampleInput2" class="form-label">City<span style={{ color: 'red' }}>*</span></label>
            <input type="text" class="form-control" name='city' value={city} onChange={(e)=>setCity(e.target.value)} aria-label='City' />
            {/* <label class="form-label">Enter City</label>
            <input  type="text" class="form-control" name='city' value={city}  aria-label='City'
              onChange={handleChange}
            /> */}
          </div>

        </Modal.Body>
        <Modal.Footer>

          <Button style={{ backgroundColor: 'blue', color: '#fff' }} onClick={() => { Update(); handleClose2() }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>



      {/* third modal start */}


      <Modal show={show2} onHide={handleClose3} className='mt-5 pt-5 my-5' style={{ backdropFilter: 'blur(4px)' }}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3">
            <label class="form-label">Add country </label>
            <input name='country' type="text" value={country} class="form-control" placeholder='Enter country'
              aria-label='Add country'
              onChange={(e)=>setCountry(e.target.value)}
            />
            {/* <ReactCountryFlag
              countryCode={"us"}
              svg
              style={{
                fontSize: '18px'
              }}
              title="US"
            /> */}
          </div>

        </Modal.Body>
        <Modal.Footer>

          <Button style={{ backgroundColor: 'blue', color: '#fff' }} onClick={() => { Update(); handleClose3() }}>
            Finish
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );
}
export { }