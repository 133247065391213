import React, { useEffect, useState } from 'react';
import i from "./Imgage/1.png"
import ReactCountryFlag from "react-country-flag"
const Order2 = () => {
  const [level_data ,setLevelData] = useState([])
  const[Co,setco] = useState()
  const [lara,setlara] = useState();

  const getfirstLevel = () => {
    var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/userlevel2", requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result.data.length)
    setLevelData(result.data)
  })
  .catch(error => console.log('error', error));
  };



  


useEffect(()=>{
  getfirstLevel()
},[])

  return (
    <>
     
    {
      level_data.map((item)=>{
        return(
          <div style={{display: "flex",alignItems:"center",justifyContent:"space-around",marginTop:"1rem"}}>
        <img src={i} style={{ width:'55px' }} />
        <h5>{item?.user_name}</h5>
        <div className='ms-auto'>
        <ReactCountryFlag
                countryCode={item?.country}
                svg
                style={{
                  fontSize:'22px' }}
                title={item?.country}/>
     </div>
        </div>
        )
      })
    }
   
    
    </>
  );
}

export default Order2;