import React, { useEffect, useState } from 'react'
import { BsFillGridFill } from "react-icons/bs";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { AiOutlineDoubleRight } from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";
import { GiHammerBreak } from "react-icons/gi";
// import { FaUserFriends } from "react-icons/fa";
import i from "./Imgage/1.png"
import p from "./Imgage/as.jpg";
import Header from './Header';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';

const Min = () => {
const [data,setData] = useState("");
const[teir1,setTeir1] = useState(0)
const[teir2,setTeir2] = useState(0)
const[active_ref,setActiveRef] = useState(0)
const [total_ice_count,setTotalIceCount] = useState();

const handleChangeTeir1 =(e)=>{
 setTeir1(e.target.value)
 setTotalIceCount((3+2*e.target.value)+(3*teir2)+(1*active_ref))

}

const handleChangeTeir2 =(e)=>{
 setTeir2(e.target.value)
 setTotalIceCount((3+3*e.target.value)+(2*teir1)+(1*active_ref))
}

const handleChangeActiveRef =(e)=>{
 setActiveRef(e.target.value)
 setTotalIceCount((3+1*e.target.value)+(3*teir2)+(2*teir2))

}


useEffect(()=>{
 setTotalIceCount((3+2*teir1)+(3*teir2)+(1*active_ref))
},[])

  const coin = async() => {
//     var formdata = new FormData();
// formdata.append("token", localStorage.getItem('token'));

// var requestOptions = {
//   method: 'POST',
//   body: formdata,
//   redirect: 'follow'
// };

// fetch("http://swamivivekanandshikshasevasansthan.in/api/rank", requestOptions)
//   .then(response => response.json())
//   .then(result => {console.log(result)
//   let mm =JSON.parse(result)
//   if (result.status == "001") {
//     setData(mm.data)
//   }
// })
//   .catch(error => console.log('error', error));


var formdata = new FormData();
formdata.append("token", localStorage.getItem('token'));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/rank", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status=="001"){
         setData(result.data) 
    }
    else{
      
    }
  })
  .catch(error => console.log('error', error));

  }

  useEffect(()=>{
    coin();
  },[])
 
  return (
    <div >  
      <div style={{ backgroundColor: '#f8f9fa', }} >


      <Header />


    </div>
      <div style={{ overflowY: "scroll", backgroundColor: 'rgb(45 124 108)', borderTopLeftRadius: "30px", borderTopRightRadius: "30px" }}>
<div style={{ height: "684px",overflowX:'hidden'}}>
 <div className='container mt-2'>
          <div className='row' style={{ color: 'white' }}>
            <div className='col-4' style={{ marginTop: '30px' }}>
              <h6> GLOBAL RANK </h6>
              <p> {data} </p>
            </div>
            <div className='col-4' style={{ marginTop: '30px' }}>
              <h6> GLOBAL RANK </h6>
              <p> 1  </p>
            </div>
            <div className='col-4' style={{ marginTop: '30px' }}>
              <h6> LEVEL </h6>
              <p> 0 </p>
            </div>
          </div>
        </div>

        <div>
          <div className='container-fiuld mt-2'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className="card" style={{ width: "100%", borderRadius: "25px", borderBottom: "5px solid rgb(6 6 6)", borderTop: "3px solid #C0C0C0", backgroundColor: '#4358b2', color: '#eee' }}>
                  <div className="container">
                    <div className="row">
                   
                      <div className="col-md-3 col-3">
                        <img src={i} style={{ height: '90px' }} />
                      </div>
                      <div className="col-md-7 col-7">
                        <h3 className='mt-2' style={{ marginLeft: '20px' }}> Iman </h3>
                        <h6 className='mt-2' style={{ marginLeft: '20px' }}> Build Inow empire </h6>
                      </div>
                      <div className="col-md-2 col-2">
                      <NavLink to='/Sno' style={{textDecoration: 'none'}}> 
                        <AiOutlineDoubleRight style={{ fontSize: '40px', marginTop: '25px',color:"white"}} />
                      </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Lottie animationData={groovyWalkAnimation} loop={true} />; */}
       <div className='container mt-3'>
          <div className='row'>
            <div className='col-8'>
              <h4 style={{ color: 'white' }}> MY BADGES </h4>
            </div>
            <div className='col-4'>
            <NavLink to='/view' style={{textDecoration: 'none'}}> 
              <h4 style={{ color: 'white' }}> view all </h4>
              </NavLink>
            </div>
          </div>
        </div>
   
     <div className='container my-3'>
          <div className='row my-3'> 
              <div className='col-4 col-md-4 col-sm-4'>
                <div className="card">
                  <div className="Header">
                       <img src={i} style={{width: '60px',marginLeft:'20px'}}/>
                  </div>
                        <div className="card-body" style={{height:'75px'}}>
                        <div style={{lineHeight:'1px'}}> 
                              <p> breaker </p>
                              <progress id="file" value="10" max="100" style={{width:'100%'}}> 100% breaker </progress>
                             <div className='d-flex mt-2 justify-content-between'>
                             <p style={{fontSize:'10px', marginLeft:'-10px'}}>social</p>
                              <p style={{fontSize:'10px',marginLeft:'20px'}}> 1 of 10</p>
                             </div>
                        </div>
                        </div>
                </div>
              </div>
              <div className='col-4 col-md-4 col-sm-4'>
                <div className="card">
                  <div className="Header">
                       <img src={i} style={{width: '60px',marginLeft:'20px'}}/>
                          <div className="card-body" style={{height:'75px'}}>
                          <div style={{lineHeight:'1px'}}> 
                              <p> purse </p>
                              <progress id="file" value="10" max="100" style={{width:'100%'}}> 32% breaker </progress>
                              <div className='d-flex mt-2 justify-content-between'>
                             <p style={{fontSize:'10px', marginLeft:'-10px'}}>coin</p>
                              <p style={{fontSize:'10px',marginLeft:'20px'}}> 1 of 10</p>
                             </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className='col-4 col-md-4 col-sm-4'>
                <div className="card">
                  <div className="Header">
                       <img src={i} style={{width: '60px',marginLeft:'20px'}}/>
                          <div className="card-body" style={{height:'75px'}}>
                          <div style={{lineHeight:'1px'}}> 
                              <p> lieutenant </p>
                              <progress id="file" value="10" max="100" style={{width:'100%'}}> 32% breaker </progress>
                              <div className='d-flex mt-2 justify-content-between'>
                             <p style={{fontSize:'10px', marginLeft:'-10px'}}>level</p>
                              <p style={{fontSize:'10px',marginLeft:'20px'}}> 1 of 10</p>
                             </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div> 


         <div>
     <div className=' mt-2'>
      <div className='row'>
        <div className='col-lg-12'>
        <div className="card" style={{ width: "100%", borderRadius: "25px", borderBottom: "5px solid rgb(6 6 6)", borderTop: "3px solid #C0C0C0" , backgroundColor: 'rgb(67, 88, 178)',color: '#eee'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-4">
            <FaUserFriends className='mt-2' style={{fontSize: '60px'}}/>
            </div>
            <div className="col-md-8 col-8 mt-2">
           <h4> invite friends</h4>
           <p> Earn extra ... by invidnl bfdkvl </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
     </div>
    </div>  
     
    <h6 style={{textAlign:"center",color:'white'}} className='mt-2'> spread the word and invite you friends. The more referrals, the more you'll eran!</h6>    
    </div>
    </div>



    <div className='container my-3 mb-5'>
      <div className='row'>
        <div className='col-lg-10 m-auto'>
        

        <div className='card mb-5 my-3 pb-4' style={{backgroundColor:'#2d7c6c', color:'#fff', borderRadius:'10px'}}>
          <div className='card-body'>
          <div >
          <h6>EARNING CALCULATOR</h6>
          <div>
            <div  style={{
              display:"flex",
              flexDirection:"column",
              alignItems:"cneter",
              justifyContent:"center",
            }}>
<h6>You will earn:</h6>
<h2>{total_ice_count}* icube/h</h2>
            </div>
            <div >
              <div style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between"
              }}>
                <h6>Tier 1</h6>
                <h6>{teir1}</h6>
              </div>
              <div>
        
     <input value={teir1}  onChange={handleChangeTeir1} min={0} max={30} type="range" style={{
      accentColor: "0d6efd",
      width: "100%"
     }}/></div>
            </div>
            <div className="row">  <div style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between"
              }}> <h6>Tier 2</h6>
                <h6>{teir2}</h6></div>



                <div>
        
        <input value={teir2}  onChange={handleChangeTeir2} min={0} max={20} type="range" style={{
         accentColor: "0d6efd",
         width: "100%"
        }}/></div>



</div>
            <div className="row">  <div style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between"
              }}> <h6>Active referrals</h6>
                <h6>{active_ref}</h6></div>
               <div>
        
     <input value={active_ref} onChange={handleChangeActiveRef} min={0} max={100} type="range" style={{
      accentColor: "0d6efd",
      width: "100%"
     }}/></div>
     
     
     </div>
          </div>
        </div>

          </div>
        </div>
        
        
        </div>
      </div>
    </div>
</div>


 


  )
}

export default Min
