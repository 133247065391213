
import { NavLink, useNavigate } from 'react-router-dom';
import './login.css';
import { useState } from 'react';
const Sign = () => {
    const navigate = useNavigate()
    const [creds,setCreds] = useState({});

    const handleChange = (e) => {
        const{name,value} = e.target; 
        setCreds({
        ...creds,
        [name]:value
    })}

   const handleSendOtp =(creds)=>{

    var formdata = new FormData();
    Object.entries(creds).map(([key,value])=>formdata.append(key,value))
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("http://swamivivekanandshikshasevasansthan.in/api/signup", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status == 1){
            localStorage.setItem("cred_email",creds.email)
        navigate("/otp")
        }else{
            console.log(result)
        }
      })
      .catch(error => console.log('error', error));
   }


    return (
       <>
       <div className="container1">
	<div className="screen">
		<div className="screen__content">
			<div className="login">
            {/* <label htmlFor="">Mobile</label> */}
				<div className="login__field">
             
					{/* <i className="login__icon fas fa-user"></i> */}
					<input name='email' type="text" onChange={handleChange} className="login__input" placeholder="Email"/>
				</div>
                {/* <label htmlFor="">Referel Id</label> */}
				<div className="login__field">
                
					{/* <i className="login__icon fas fa-lock"></i> */}
					{/* <input name='refer_id' type="text" onChange={handleChange} className="login__input" placeholder="Referel Id"/> */}
				</div>
                <div>
				<button onClick={()=>handleSendOtp(creds)} className="button login__submit" style={{textAlign:'center'}}>
					<span className="button__text " >Send Otp</span> 
				</button>
                <p className='pt-3 text-center'>
                  create new   <NavLink to="/Login"> account</NavLink> 
                </p>				
			</div>
			</div>
		</div>
		<div className="screen__background">
			<span className="screen__background__shape screen__background__shape4"></span>
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
       </div>
       </>
    );
};

export default Sign;