// AuthRoute.js (inside the src/ directory)
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthRoute = () => {
  console.log("gg",localStorage.getItem('token'));
  if (!localStorage.getItem('token')) {
    return <Navigate to="/sign" />;
  } else {
    return <Outlet />;
  }
};
