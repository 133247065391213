import  { useEffect, useState } from 'react';
import i from "./Imgage/1.png"
import ReactCountryFlag from 'react-country-flag';

const Order3 = () => {
  const [level_data ,setLevelData] = useState([])
  const getfirstLevel = () => {
    var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));
formdata.append("level", "2");

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/userlevel3", requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    setLevelData(result)
  })
  .catch(error => console.log('error', error));
  };

useEffect(()=>{
  getfirstLevel()
},[])

  return (
    <>
    {
      level_data.map((item)=>{
        return(
          <div style={{display: "flex",alignItems:"center",justifyContent:"space-around",marginTop:"1rem"}}>
    <img src={i} style={{ height: '55px' }} />
      <h5>{item?.user_name}</h5>
      <div className='ms-auto'>
     <ReactCountryFlag
                countryCode={item?.country}
                svg
                style={{  fontSize:'18px'
                }}
                title={item?.country}
            />  
     </div>
    </div>
        )
      })
    }
   
    
    </>
  );
}

export default Order3;