import React from 'react'
import img from './Imgage/a.jpg'
import { BiArrowBack } from 'react-icons/bi';
import {
  useNavigate
} from 'react-router-dom';
const Sno = () => {
  const navigate = useNavigate();
  return (
    <>
    <div className='ms-1 p-2' onClick={() => { navigate("/Min")}} ><BiArrowBack  className='border border-warning fw-bold fs-5 ' style={{width:"5rem",borderRadius:"7px"}} /></div>
     <div className='container' style={{height:"120vh",overflowX:"scroll"}}>
        <div className='row'>
            <div className='col-12'>
            <div className="card" style={{width: '100%',backgroundColor:"#f4f7f7"}}>
                <img src={img} className="card-img-top mx-auto d-block" alt="" style={{width: '70%',height:'30vh'}}/>
                 <div className='text-center'>
                 <h1> Iman</h1>
                 <h5>Build a Iqube empire</h5>
                <p>earn by checking in every 24 hours to show your commitment to the Iqube network and prove that you're a human, not a robot</p>
                 </div>
                </div> 
            </div>
            <div className='col-12 mt-5'>
            <div className="card" style={{width: '100%',backgroundColor:"#f4f7f7"}}>
                <img src={img} className="card-img-top mx-auto d-block" alt="" style={{width: '70%',height:'30vh'}}/>
                 <div className='text-center'>
                 <h1> Iman</h1>
                 <h5>Build a Iqube empire</h5>
                <p>earn by checking in every 24 hours to show your commitment to the  network and prove that you're a human, not a robot</p>
                 </div>
                </div> 
            </div>
        </div>
     </div>  
    </>
  )
}

export default Sno
