import React,{useState,useEffect} from 'react'
import imk from "./Imgage/1.png" 

const Coin = () => {

    const [name,setName]= useState('');
    const [name1,setName1]= useState();
    const [name2,setName2]= useState();
    const [name3,setName3]= useState();
    const [name4,setName4]= useState();
    const [name5,setName5]= useState();
    const [name6,setName6]= useState();
    const [activeDataStyle,setActiveDataStyle]= useState();

const Update =()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/coin", requestOptions)
  .then(response => response.json())
  .then(result => {
        console.log(result)
      if(result.status=="001"){
              setName(result.data.Chilly_Charlie);
          setName1(result.data.Frigid_Faye);
          setName2(result.data.Brrr_Bounty);
          setName3(result.data.Arctic_Aces);
          setName4(result.data.Frozen_Fundraiser);
          setName5(result.data.Snowy_Stash);
          setName6(result.data.Tundra_Treasure);
          

          // const activeData = result.data.Active_Data;

          // const color = activeData === "complete" ? "green" : "red";
          // const backgroundColor = activeData === "complete" ? "lightgreen" : "lightcoral";
   
          // setActiveDataStyle({ color, backgroundColor });
    }

    else{
       console.log(result,"error")
    }
  })
  .catch(error => console.log('error', error));    

}

useEffect(()=>{
    Update();
},[])
  return (
    <>
    
    <div className="container" style={{paddingBottom:'100px'}}>
    <div className="row">
        <div className='col-12 mt-4'>  

            <div  style={{height:'80vh',overflowY:'scroll'}}>
        
            <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
            <div className='card-body py-0 py-2 '>
            <div className='row'>
            <div className='col-3'>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
            </div>
            </div> 

            <div className='col-5'>
            <div style={{lineHeight:'8px'}}>
            <h6>Chilly_Charlie </h6>
            <p style={{color:'#8d8d8d'}}>  50 </p>
            </div>
            </div> 

            {/* style={{  borderRadius:'8px', padding:'5px 0px', backgroundColor:data.result==="panding"?"blue":"red"}} */}

            <div className='col-4 '>
            <div style={{lineHeight:'0px'}}>  
            <h6 style={{  borderRadius:'8px', padding:'5px 0px',textAlign:'center', 

           backgroundColor:name=="pending"?"lightgray":name=="active"?"#d2ffd2":name=="complete"?"#adffb6":'#000',
           color:name=="pending"?"orange":name=="active"?"lightgreen":name=="complete"?"#063700":"white"
           }}>{name}</h6>
            <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
            </div>  
            </div>

            </div>
            </div>
            </div>  


            
            <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
            <div className='card-body py-0 py-2 '>
            <div className='row'>
            <div className='col-3'>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
            </div>
            </div> 

            <div className='col-5'>
            <div style={{lineHeight:'8px'}}>
            <h6>Frigid_Faye </h6>
            <p style={{color:'#8d8d8d'}}> 150 </p>
            </div>
            </div> 

            <div className='col-4 '>
            <div style={{lineHeight:'0px'}}>
            <h6 style={{ borderRadius:'8px', padding:'5px 0px', textAlign:'center',
           
           backgroundColor:name1=="pending"?"lightgray":name1=="active"?"#d2ffd2":name1=="complete"?"#0d790d":'red',

           color:name1=="pending"?"orange":name1=="active"?"green":name1=="complete"?"#063700":"#000"
          }}>{name1}</h6> 
            <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
            </div>  
            </div>  

            </div>
            </div>
            </div>  


            
            <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
            <div className='card-body py-0 py-2 '>
            <div className='row'>
            <div className='col-3'>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
            </div>
            </div> 

            <div className='col-5'>
            <div style={{lineHeight:'8px'}}>
            <h6> Brrr_Bounty </h6>
            <p style={{color:'#8d8d8d'}}>  900 </p>
            </div>
            </div> 

            <div className='col-4 '>
            <div style={{lineHeight:'0px'}}>
            <h6 style={{ borderRadius:'8px', padding:'5px 0px',textAlign:'center',

            backgroundColor:name2=="pending"?"lightgray":name2=="active"?"#d2ffd2":name2=="complete"?"#0d790d":'red',

           color:name2=="pending"?"#545454":name2=="active"?"green":name2=="complete"?"green":"#000"
            
            }}>{name2}</h6>
            <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
            </div>  
            </div>

            </div>
            </div>
            </div>  

            
            <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
            <div className='card-body py-0 py-2 '>
            <div className='row'>
            <div className='col-3'>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
            </div>
            </div> 

            <div className='col-5'>
            <div style={{lineHeight:'8px'}}>
            <h6> Arctic_Aces </h6>
            <p style={{color:'#8d8d8d'}}> 2000 </p>
            </div>
            </div> 

            <div className='col-4 '>
            <div style={{lineHeight:'0px'}}>
            <h6 style={{ borderRadius:'8px', padding:'5px 0px',textAlign:'center',

          backgroundColor:name3=="pending"?"lightgray":name3=="active"?"#d2ffd2":name3=="complete"?"#0d790d":'red',

          color:name3=="pending"?"#545454":name3=="active"?"green":name3=="complete"?"green":"#000"

}}>{name3}</h6>
            <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
            </div>  
            </div>

            </div>
            </div>
            </div>  

            
            <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
            <div className='card-body py-0 py-2 '>
            <div className='row'>
            <div className='col-3'>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
            </div>
            </div> 

            <div className='col-5'>
            <div style={{lineHeight:'8px'}}>
            <h6> Frozen_Fundraiser </h6>
            <p style={{color:'#8d8d8d'}}> 8000 </p>
            </div>
            </div> 

            <div className='col-4 '>
            <div style={{lineHeight:'0px'}}>
            <h6 style={{  borderRadius:'8px', padding:'5px 0px',textAlign:'center',

            backgroundColor:name4=="pending"?"lightgray":name4=="active"?"#d2ffd2":name4=="complete"?"#0d790d":'red',

            color:name4=="pending"?"#545454":name4=="active"?"green":name4=="complete"?"green":"#000"

}}>{name4}</h6>
            <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
            </div>  
            </div>

            </div>
            </div>
            </div>  
            
            <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
            <div className='card-body py-0 py-2 '>
            <div className='row'>
            <div className='col-3'>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
            </div>
            </div> 

            <div className='col-5'>
            <div style={{lineHeight:'8px'}}>
            <h6>Snowy_Stash</h6>
            <p style={{color:'#8d8d8d'}}> 10000 </p>
            </div>
            </div> 

            <div className='col-4 '>
            <div style={{lineHeight:'0px'}}>
            <h6 style={{ borderRadius:'8px', padding:'5px 0px', textAlign:'center',

          backgroundColor:name5=="pending"?"lightgray":name5=="active"?"#d2ffd2":name5=="complete"?"#0d790d":'red',

          color:name5=="pending"?"#545454":name5=="active"?"green":name5=="complete"?"green":"#000"}}>{name5}</h6>
            <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
            </div>  
            </div>

            </div>
            </div>
            </div>  
            
            <div className='card mt-3 demo w-100' style={{borderRadius:'13px'}}>
            <div className='card-body py-0 py-2 '>
            <div className='row'>
            <div className='col-3'>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img style={{width:"90%"}} src={imk} class="card-img-top" alt=""/>
            </div>
            </div> 

            <div className='col-5'>
            <div style={{lineHeight:'8px'}}>
            <h6> Tundra_Treasure </h6>
            <p style={{color:'#8d8d8d'}}> 50000 </p>
            </div>
            </div> 

            <div className='col-4 '>
            <div style={{lineHeight:'0px'}}>
            <h6 style={{  borderRadius:'8px', padding:'5px 0px',
          textAlign:'center',

          backgroundColor:name6=="pending"?"lightgray":name6=="active"?"#d2ffd2":name6=="complete"?"#0d790d":'red',

          color:name6=="pending"?"#545454":name6=="active"?"green":name6=="complete"?"green":"#000"}}>{name6}</h6>
            <progress id="file" value="30" max="100" style={{width:'100%', }}> 100% breaker </progress>
            </div>  
            </div>

            </div>
            </div>
            </div>  

        </div>
    </div>
</div>
</div>

 
    </>
  )
}

export default Coin;