
import React, { useEffect, useState } from 'react';
import './Order.css';
// import {useNavigate} from 'react-router-dom';
import Order1 from './Order1';
import Order2 from "./Order2";
import Order3 from './Order3';
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { FaGripLinesVertical } from "react-icons/fa";
import { PiWalletBold } from "react-icons/pi";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { MdMarkEmailRead } from "react-icons/md";
import ReactFlagsSelect from "react-flags-select";
 
import 'react-toastify/dist/ReactToastify.css';

import i from "./Imgage/1.png"
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { NearMe } from '@mui/icons-material';
import { upload } from '@testing-library/user-event/dist/upload';

export default function Profile() { 
    const [State, setState] = useState("")
    const [name,setName] = useState('');
    const [email,setEmail] = useState();
    const [Username,setUsername] = useState();
    const [country,setCountry] = useState();
    const [city,setCity] = useState(); 
    const [selected, setSelected] = useState("");

    const [loading, setLoading] = useState(false);



    const demo =()=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("http://swamivivekanandshikshasevasansthan.in/api/profile", requestOptions)
      .then(response => response.json())
      .then(result => { 
        console.log(result,"city jlhkhkhkhkj");
        if(result.status=="001"){
          setName(result.data.user_name) ;
          setUsername(result.data.name);
          setEmail(result.data.email);
          setCountry(result.data.country);
          setCity(result.data.city);
        }
        else{ 
            
         }
      })
      .catch(error => console.log('error', error));
    }



    const Update = ()=>{
      setLoading(true)
      console.log(city,"hjgfhjkl;jghjkl;")
 var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));
formdata.append("name", Username); 
formdata.append("country", country);
formdata.append("city", city);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/update", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result,"hellof");
    if(result.status=="001"){
       toast(result.message)
    }else{
      toast(result.message)
    }
  })
  .catch(error => console.log('error', error))
   .finally(()=>{setLoading(false)});
    }

    useEffect(() => {
        setState('Order3');
        demo();
    }, []);
  


  return (
    <>

    <ToastContainer/>
    
    <section  style={{backgroundColor:"rgb(45, 124, 108)",height:"60vh"}}>
        <div className='container'>
            <div className='row '>
  <div className='col-2 mt-2'>
  <NavLink to="/">
 <AiOutlineArrowLeft style={{color:'#fff'}}/>
  </NavLink>
  </div>
  <div className='col-8'>
  <div style={{display:'flex', justifyContent:'center'}}>
    

               <img src={i} style={{width:'25%',}}></img>
  </div>
  </div>
            </div>
            <p style={{marginLeft:'43%',color:'white'}}> {name} </p>
        </div>
        <div class="card mt-3" style={{ width:"100%",height:"60vh",borderRadius:"25px 25px 0px 0px",borderBottom:"none"}}>
        <div>
  <div class="my-3"  >
    {/* <label for="exampleInputEmail1" class="form-label ps-3">Email address</label> */}
    {/* <div class="my-4" style={{display:"flex",justifyContent:'center'}}>
    <input type="email" class="form-control"  style={{width:'90%',backgroundColor:'#fff7f7'}}/>
    </div> */}
  

 
  </div>


  <div className='container'>
    <div className='row'>
      <div className='col-lg-8 m-auto'>
        

      {/* <label>nick Name</label> */}
      <div className="input-group flex-nowrap mb-3" style={{}}>
  <span className="input-group-text" id="addon-wrapping" style={{backgroundColor:'#fff7f7',borderRight:"none"}}><AiOutlineUser/></span>
  <input type="text" value={name} className="form-control py-2" placeholder="" aria-label="Username" aria-describedby="addon-wrapping" disabled  style={{backgroundColor:'#fff7f7',borderLeft:'none',boxShadow:'none'}} 
    onChange={(e)=>{setName(e.target.value)}}/>
</div>  



<div className="input-group flex-nowrap mb-3" style={{}}>
  <span className="input-group-text" id="addon-wrapping" style={{backgroundColor:'#fff7f7',borderRight:"none"}}><AiOutlineUser/></span>
  <input type="text" value={Username} className="form-control py-2" placeholder="Name" aria-label="Username" aria-describedby="addon-wrapping"   style={{backgroundColor:'#fff7f7',borderLeft:'none',boxShadow:'none'}}
    onChange={(e)=>{setUsername(e.target.value)}}/>
</div>

<div className="input-group flex-nowrap mb-3" style={{}}>
  <span className="input-group-text" id="addon-wrapping" style={{backgroundColor:'#fff7f7',borderRight:"none"}}><MdMarkEmailRead/></span>
  <input type="email" value={email} className="form-control py-2" placeholder="Email" aria-label="Username" aria-describedby="addon-wrapping" disabled   style={{backgroundColor:'#fff7f7',borderLeft:'none',boxShadow:'none'}} 
  onChange={(e)=>{setEmail(e.target.value)}}/>
</div>

  
 

<div className="input-group flex-nowrap mb-3" style={{}}>
  <span className="input-group-text" id="addon-wrapping" style={{backgroundColor:'#fff7f7',borderRight:"none"}}><AiOutlineUser/></span>
  <input type="text" value={city} className="form-control py-2" placeholder="Name" aria-label="Username" aria-describedby="addon-wrapping"   style={{backgroundColor:'#fff7f7',borderLeft:'none',boxShadow:'none'}}
    onChange={(e)=>{setCity(e.target.value)}}/>
</div>




 
{/* <div   style={{}}>
   <ReactFlagsSelect
    selected={country}
    onSelect={(code) => setCountry(code)}
    value={country}
  />
</div>  */}
      </div>  
    </div>
  </div>
   
   <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
  <button type="submit" class="btn btn-primary" style={{textAlign:'center',width:'60%'}} onClick={()=>{Update()}}>
    {
      loading==false?"Update":"Loading....."
    }
  </button>
   </div>
</div>
            
        </div>
     




      
    </section>
    </>
  )
}
