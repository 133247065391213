import { useEffect, useState } from 'react';
import i from "./Imgage/1.png"
import ReactCountryFlag from "react-country-flag"
const Order1 = () => {
  const [level_data ,setLevelData] = useState({})
  const[Co,setco] = useState()

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  
  const getfirstLevel = () => {
    // var formdata = new FormData();
    // formdata.append(
    //   "token", localStorage.getItem("token")
    // );

    // var requestOptions = {
    //   method: "POST",
    //   body: formdata,
    //   redirect: "follow",
    // };

    // fetch(
    //   "http://swamivivekanandshikshasevasansthan.in/api/levelfirst",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if(result.status == 1){
    //       setLevelData(result.data)
    //     }
    //     console.log(result, "1");
    //   })
    //   .catch((error) => console.log("error", error));


    var formdata = new FormData();
formdata.append("token",localStorage.getItem("token"));

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("http://swamivivekanandshikshasevasansthan.in/api/userlevel1", requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result.data.country)
    setco(result.data.country)
    setLevelData(result.data)
  })
  .catch(error => console.log('error', error));
  };

useEffect(()=>{
  getfirstLevel()
},[])

  return (
    <>
    <div style={{display: "flex",alignItems:"center",marginTop:"1rem",}}>
    <img src={i} style={{ width:'55px' }} />
      <h5>{level_data?.user_name}</h5>
      <p> {level_data?.contry}</p>
     <div className='ms-auto'>
     <ReactCountryFlag
                countryCode={Co}
                svg
                style={{fontSize:'18px'
                }}
                title="US"
            />
     </div>
      <p></p>
    </div>
    </>
  );
}

export default Order1;