
import React, { useEffect, useState } from 'react';
import './Order.css';
// import {useNavigate} from 'react-router-dom';
import Order1 from './Order1';
import Order2 from "./Order2";
import Order3 from './Order3';
import { AiOutlineSearch } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { FaGripLinesVertical } from "react-icons/fa";
import { PiWalletBold } from "react-icons/pi";
import Social from './Social';
import Coin  from './Coin'
import { NavLink } from 'react-router-dom';
import Leval3 from './Leval3';

export default function View() {
    const [State, setState] = useState("")
    useEffect(() => {
        setState('Social')
    }, []);

   

  return (
    <>
    <section style={{height:"60vh"}}>
        <div class="card     " style={{ width:"100%",height:"50vh",borderRadius:"25px 25px 0px 0px",borderBottom:"none"}}>
            <div class="card-body">
                <div className="OrderCard-fluid card p-2  demo" style={{ width: "100%",borderRadius:"20px" }}>
                    <div className="row"  style={{zIndex:'999'}}>
                        <div className='col-6'>
                            <div className='text-center'>
                                <button onClick={() => { setState("Social") }} className='OrderBtnAll ' style={{ border: "none", backgroundColor: State === 'Social' ? 'rgb(45, 124, 108)' : 'white', color: State === 'Social' ? '#FFFFFF' : '#000000' }}>Social</button>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='text-center'>
                                <button onClick={() => { setState("Coin") }} className='OrderBtnPending' style={{ border: "none", backgroundColor: State === 'Coin' ? 'rgb(45, 124, 108)' : 'white', color: State === 'Coin' ? '#FFFFFF' : '#000000' }}>Coins</button>
                            </div>
                        </div>
                        {/* <div className='col-4'>
                            <div className='text-center'>
                                <button onClick={() => { setState("Leval3") }} className='OrderBtnComplete ' style={{ border: "none", backgroundColor: State === 'Leval3' ? 'rgb(45, 124, 108)' : 'white', color: State === 'Leval3' ? '#FFFFFF' : '#000000' }}>Level</button>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div>
            {State === "Social" || State === "" ? <><Social /></> : <></>}
            {State === "Coin" ? <><Coin /></> : <></>}
            {/* {State === "Leval3" ? <><Leval3 /></> : <></>} */}
        </div>
            </div>
            
        </div>
      
    </section>
    </>
  )
}
